import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  Grid,
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { useNavigate } from 'react-router';
import ExportLink from './ExportLink';
import { useGetRepMe } from 'src/hooks/repHooks';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const Toolbar = ({
  className,
  searchValue,
  setSearchValue,
  isExportEnabled,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { data: repData } = useGetRepMe();

  const [search, setSearch] = React.useState('');
  const onSearchChangeHandler = ({ target }) => {
    setSearchValue(target.value);
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" justifyContent="flex-end">
        {/* <Button className={classes.exportButton}>Export</Button> */}
        <Button
          onClick={() =>
            (window.location.href = `${process.env.REACT_APP_AUTH0_ISSUER}/authorize?response_type=code&scope=https%3A%2F%2Fapi.simplisafe.com%2Fscopes%2Fuser%3Aplatform%20openid%20email%20offline_access%20&client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_AUTH0_CALLBACK}&connection=ss-users&device_id=${process.env.REACT_APP_DEVICE_ID}&device=PCS%20WebApp&audience=https://api.simplisafe.com/`)
          }
          color="primary"
          variant="contained"
          disabled={repData?.role === 'admin' ? false : true}
        >
          Add subscriber
        </Button>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={6}>
                <Box maxWidth={500}>
                  <TextField
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Search customer"
                    variant="outlined"
                    value={search}
                    onChange={(event) => setSearch(event.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        onSearchChangeHandler(e);
                      }
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box display="flex" justifyContent="flex-end">
                  <ExportLink
                    searchValue={searchValue}
                    isEnabled={isExportEnabled}
                  />
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
  isExportEnabled: PropTypes.bool,
};

export default Toolbar;
