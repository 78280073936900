import React, { useState } from 'react';
import DenseCardHeader from 'src/components/DenseCardHeader';
import {
  Box,
  Button,
  Grid,
  Card,
  Divider,
  makeStyles,
  Paper,
  Typography,
  TextareaAutosize,
  TextField,
} from '@material-ui/core';
import { useQueryParams } from 'src/utils/useQueryParams';
import { useKey } from 'src/context/hotkey-context';
import { Disposition, ThreatLevel } from './Disposition';
import {
  useSetEventDocumentation,
  useSetPcsEventStatus,
} from 'src/hooks/eventHooks';
import { useNavigate } from 'react-router';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSubscription } from 'src/hooks/useSubscription';
import {
  createOpenMetricDispatch,
  getEventInQueueDuration,
} from 'src/context/Metrics-context';
import { EventDocumentation, handleChangeCommitted } from './Documentation';

const useStyles = makeStyles(() => ({
  root: {
    padding: '4px 0px',
  },
  timelineItem: {
    minHeight: '40px',
  },
  opposite: {
    padding: '3px 10px',
    flex: '0.05',
  },
  content: {
    padding: '0 4px 4px 4px',
  },
  title: {
    padding: '8px',
  },
}));

export default function FollowUpSteps({
  subscription,
  eventQuery,
  metrics,
  dispatch,
  isEmailUntouched,
  onDone = () => null,
}: Props) {
  const classes = useStyles();
  const [eventDocumentation, setEventDocumentation] = useState('');
  const { eventId, userId, sid } = useQueryParams();
  const setDocumentationMutation = useSetEventDocumentation(eventId);
  const [stepNumber, setStepNumber] = React.useState(1);
  const sub = useSubscription({ userId: userId, sid: sid });

  const pcsStatusMutation = useSetPcsEventStatus();
  const navigate = useNavigate();
  const markComplete = React.useCallback(() => {
    onDone();
    pcsStatusMutation.mutate({
      eventId,
      pcsStatus: -30,
    });
    metrics.fetchNewData();
    dispatch({
      type: 'SEND',
      payload: {
        ...metrics.payload({ eventID: eventId }),
        metricName: 'follow-up-complete-notification',
        eventID: eventId,
      },
    });

    // Send metric for how long the event was in the queue
    if (eventId) {
      const openMetricDispatch = createOpenMetricDispatch(dispatch);
      openMetricDispatch({
        metricName: 'event-handle-time',
        payload: {
          eventId,
          duration: getEventInQueueDuration(eventId),
          PCSstatus: 'Follow up',
        },
      });
    }

    navigate('/app/queue');
  }, [eventQuery.data?.disposition?.action, isEmailUntouched]);

  const stepTitle = {
    1: 'Close the Loop',
    2: 'Event Documentation',
    3: 'Finalize Event',
  };

  useKey(
    ']',
    () => setStepNumber(stepNumber < 3 ? stepNumber + 1 : stepNumber),
    'Next Verify Step'
  );
  useKey(
    '[',
    () => setStepNumber(stepNumber > 1 ? stepNumber - 1 : stepNumber),
    'Previous Verify Step'
  );

  const handleNext = () => {
    setStepNumber(stepNumber + 1);
    handleChangeCommitted({
      documentation: eventDocumentation,
      setDocumentationMutation,
      eventId,
      dispatch,
    });
  };

  return (
    <Card>
      <DenseCardHeader title={stepTitle[stepNumber]} />
      <Box height="100%" marginTop="3px">
        <Grid item xs={12} sm={12}>
          <Box height="100%">
            {stepNumber === 1 && (
              <>
                <Paper
                  square
                  elevation={5}
                  className={classes.content}
                  style={{ height: '38vh' }}
                >
                  <Box height="33vh">
                    <Typography
                      variant="body2"
                      style={{
                        marginTop: 12,
                        marginBottom: 6,
                        paddingLeft: 18,
                        paddingRight: 18,
                      }}
                    >
                      • <b>Reject</b> invalid Person and Face images, if any
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{
                        marginBottom: 6,
                        paddingLeft: 18,
                        paddingRight: 18,
                      }}
                    >
                      • Select <b>Important</b> on 1-2 key images to attach them
                      to customer email
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{
                        paddingLeft: 18,
                        paddingRight: 18,
                      }}
                    >
                      • <b>Notify Customer</b> using pre-populated email and
                      push notification templates. Send to customer and complete
                      event handling
                    </Typography>
                  </Box>

                  <Divider />
                  <Box
                    display="flex"
                    justifyContent="space-evenly"
                    py={1}
                    px={2}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setStepNumber(stepNumber + 1)}
                    >
                      Next
                    </Button>
                  </Box>
                </Paper>
              </>
            )}
            {stepNumber === 2 && (
              <>
                <Paper
                  square
                  elevation={5}
                  className={classes.content}
                  style={{ height: '38vh' }}
                >
                  <Box height="33vh">
                    <EventDocumentation
                      documentation={eventDocumentation}
                      setDocumentation={setEventDocumentation}
                    />
                  </Box>

                  <Divider />
                  <Box
                    display="flex"
                    justifyContent="space-evenly"
                    pt={0.5}
                    px={2}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleNext()}
                      disabled={eventDocumentation.length === 0}
                    >
                      Next
                    </Button>
                  </Box>
                </Paper>
              </>
            )}

            {stepNumber === 3 && (
              <>
                <Paper
                  square
                  elevation={5}
                  className={classes.content}
                  style={{ height: '100%' }}
                >
                  <Typography>Event Disposition</Typography>
                  <PerfectScrollbar>
                    <Box
                      display="flex"
                      justifyContent="space-around"
                      flexWrap="wrap"
                      m={2}
                      height="28vh"
                    >
                      <Disposition
                        eventId={eventId}
                        actionId={eventQuery.data?.disposition?.action}
                        metrics={metrics}
                        dispatch={dispatch}
                      />
                      <ThreatLevel
                        eventId={eventId}
                        threatLevel={eventQuery.data?.disposition?.threat_level}
                        metrics={metrics}
                        dispatch={dispatch}
                      />
                    </Box>
                  </PerfectScrollbar>
                  <Divider />
                  <Box display="flex" justifyContent="space-evenly" m={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => markComplete()}
                      disabled={!eventQuery.data?.disposition?.action}
                    >
                      Complete
                    </Button>
                  </Box>
                </Paper>
              </>
            )}
          </Box>
        </Grid>
      </Box>
    </Card>
  );
}

// TODO: create subscription type
interface Props {
  subscription: any;
  eventQuery: any;
  metrics: any;
  dispatch: any;
  isEmailUntouched: any;
  onDone: () => void;
}
