import DOMPurify from 'dompurify';
import React, { useState, Fragment } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  makeStyles,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
} from '@material-ui/core';
import { useListAuditEmailQuery } from 'src/hooks/auditHooks';
import { LoadingSpinner } from 'src/components/LoadingSpinner';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const EmailList = ({ eventId }) => {
  const classes = useStyles();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const params = {
    limit,
    skip: page * limit,
    event_id: eventId,
  };

  const emailsQuery = useListAuditEmailQuery(params);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  if (!eventId)
    return (
      <Typography style={{ marginBottom: 20 }}>No email(s) yet.</Typography>
    );
  if (emailsQuery.isLoading) return <LoadingSpinner />;
  if (!emailsQuery.data?.length)
    return (
      <Typography style={{ marginBottom: 20 }}>No email(s) yet.</Typography>
    );

  return (
    <Card className={clsx(classes.root)}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <List>
            {emailsQuery.data?.slice(0, limit).map((email, i) => (
              <Fragment key={i}>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={`Event ID ${email.eventId}: ${
                      email.subject
                    } - ${moment.utc(email.sent_date).local().format('lll')}`}
                    secondary={
                      <>
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="textPrimary"
                        >
                          to {email.to_uid}, from {email.agent_uid}
                        </Typography>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(email.body),
                          }}
                        />
                      </>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
              </Fragment>
            ))}
          </List>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

EmailList.propTypes = {
  eventId: PropTypes.number,
};

export default EmailList;
