import React from 'react';
import { Box, Container, Grid, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Header from './Header';
import EventList from './EventList';
import Overview from './Overview';
import RepList from './RepList';
import EventsHandled from './EventsHandled/EventsHandled';
import SampleImages from './SampleImages';
import ActiveUsers from './ActiveUsers';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

const DashboardAlternativeView = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Dashboard Alternative">
      <Container maxWidth={false}>
        <Header />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Overview />
          </Grid>
          <Grid item xs={6}>
            <Box height="35vh">
              <EventsHandled />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box height="35vh">
              <ActiveUsers />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box height="35vh">
              <RepList />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box height="40vh" overflow="scroll">
              <EventList />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box height="40vh">
              <SampleImages />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default DashboardAlternativeView;
