import React from 'react';
import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import { usePcsClient } from 'src/context/pcs-auth-context';

interface RecordingSession {
  recording_url: string;
  eventId?: number;
  agent_uid: number;
  start_time: Date;
  end_time: Date;
}

export function useAddRecordingSession() {
  const client = usePcsClient();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation(
    (recording: RecordingSession) =>
      client('manage-session/add', {
        method: 'POST',
        data: recording,
      }),
    {
      onError: (error) => {
        enqueueSnackbar(`Endpoint: manage-session/add. ${error}`, {
          variant: 'error',
        });
      },
    }
  );

  return mutation;
}


export function useGeneratePresignedURL() {
  const client = usePcsClient();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation(
    (file_name: string) =>
      client('manage-session/generate_url', {
        method: 'POST',
        data: {file_name},
      }),
    {
      onError: (error) => {
        enqueueSnackbar(`Endpoint: manage-session/add. ${error}`, {
          variant: 'error',
        });
      },
    }
  );

  return mutation;
}
